<template>
  <div class="globe-container">
    <!-- Added Title -->
    <div class="global-title">
      <h2 class="font-coolvetica">CRYPTO TENDENCIA GLOBAL</h2>
    </div>

    <div class="globe-wrapper">
      <div ref="globeContainer" class="globe"></div>
    </div>

    <!-- Crypto Cards -->
    <div class="crypto-cards">
      <div v-for="(coin, index) in selectedCoins" :key="coin.id" :class="['crypto-card', getPositionClass(index)]">
        <img :src="coin.icon" :alt="coin.name" class="crypto-icon" />
        <div class="crypto-info">
          <div class="crypto-name font-coolvetica letter-spacing-03">{{ coin.name }} ({{ coin.symbol }})</div>
          <div class="crypto-price font-coolvetica letter-spacing-03">Precio: ${{ formatNumber(coin.price) }}</div>
          <div class="crypto-cap font-coolvetica letter-spacing-03">Market Cap: ${{ formatNumber(coin.marketCap) }}
          </div>
        </div>
      </div>
    </div>

    <!-- Stats Section -->
    <div class="stats-wrapper">
      <!-- Desktop Stats Card -->
      <div class="community-stats-card desktop-stats">
        <div class="stats-container p-4 font-coolvetica letter-spacing-03">
          <div class="stats-title">COMUNIDAD <br> GLOBAL</div>
          <div class="stats-title">
            <p class="mb-0" style="font-size: 7rem;">+500</p>
            <p style="font-size: 1.5rem;">USUARIOS</p>
          </div>
          <div class="stats-labels">
            <p class="stats-number">NUEVAS CUENTAS</p>
            <p class="stats-number">NUEVAS CUENTAS EN LOS ULTIMOS 7 DIAS</p>
            <p class="stats-number">PAISES BLOCK BLAST</p>
          </div>
          <div class="stats-values">
            <p class="stats-number">+100</p>
            <p class="stats-number">+30</p>
            <p class="stats-number">+5</p>
          </div>
        </div>
      </div>

      <!-- Mobile Stats Card -->
      <div class="community-stats-card mobile-stats">
        <div class="mobile-stats-container font-coolvetica letter-spacing-03">
          <div class="mobile-stats-header">
            <h3 class="mobile-stats-title">COMUNIDAD <br> GLOBAL</h3>
          </div>
          <div class="mobile-stat-item">
            <p class="mobile-stat-label">USUARIOS</p>
            <p class="mobile-stat-value">+500</p>
          </div>
          <div class="mobile-stat-item">
            <p class="mobile-stat-label">NUEVAS CUENTAS</p>
            <p class="mobile-stat-value">+100</p>
          </div>
          <div class="mobile-stat-item">
            <p class="mobile-stat-label">NUEVAS CUENTAS EN LOS ULTIMOS 7 DIAS</p>
            <p class="mobile-stat-value">+30</p>
          </div>
          <div class="mobile-stat-item">
            <p class="mobile-stat-label">PAISES BLOCK BLAST</p>
            <p class="mobile-stat-value">+5</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch, onMounted, onUnmounted } from 'vue';
import Globe from 'globe.gl';

export default defineComponent({
  name: 'GlobeComponent',

  props: {
    coins: {
      type: Array,
      required: true,
      default: () => []
    }
  },

  setup(props) {
    const globeContainer = ref(null);
    const selectedCoins = ref([]);
    let globe;
    let initialSelectionDone = false;

    const selectRandomCoins = () => {
      if (props.coins.length > 0 && !initialSelectionDone) {
        const shuffled = [...props.coins].sort(() => 0.5 - Math.random());
        selectedCoins.value = shuffled.slice(0, 5);
        initialSelectionDone = true;
      }
    };

    const formatNumber = (value, decimals = 2) => {
      if (value === undefined || value === null) {
        return '0';
      }
      const num = Number(parseFloat(value));
      if (isNaN(num)) {
        return '0';
      }
      return num.toLocaleString(undefined, {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
      });
    };

    const getPositionClass = (index) => {
      const positions = ['top-left', 'top-right', 'left-center', 'bottom-left', 'bottom-right'];
      return positions[index];
    };

    const generateColorPalette = (numColors) => {
      const colors = [];
      for (let i = 0; i < numColors; i++) {
        const hue = (i * 360) / numColors;
        colors.push(`hsla(${hue}, 100%, 50%, 0.6)`);
      }
      return colors;
    };

    const generateRealisticCables = () => {
      const cables = [];
      const numCables = 200;
      const landingPoints = [];
      const colorPalette = generateColorPalette(20);

      for (let i = 0; i < 50; i++) {
        landingPoints.push({
          lat: (Math.random() - 0.5) * 150,
          lng: Math.random() * 360 - 180
        });
      }

      for (let i = 0; i < numCables; i++) {
        const start = landingPoints[Math.floor(Math.random() * landingPoints.length)];
        const end = landingPoints[Math.floor(Math.random() * landingPoints.length)];

        if (start !== end) {
          cables.push({
            startLat: start.lat,
            startLng: start.lng,
            endLat: end.lat,
            endLng: end.lng,
            color: colorPalette[i % colorPalette.length]
          });
        }
      }

      return cables;
    };

    const initGlobe = () => {
      const simulatedCables = generateRealisticCables();

      globe = Globe()
        .globeImageUrl('//unpkg.com/three-globe/example/img/earth-dark.jpg')
        .bumpImageUrl('//unpkg.com/three-globe/example/img/earth-topology.png')
        .backgroundImageUrl('//unpkg.com/three-globe/example/img/night-sky.png')
        .arcsData(simulatedCables)
        .arcColor('color')
        .arcStroke(0.3)
        .arcCurveResolution(64)
        .arcDashLength(0.8)
        .arcDashGap(2)
        .arcDashAnimateTime(1000)
        .arcsTransitionDuration(1000)
        .arcAltitudeAutoScale(0.15)
        .atmosphereColor('rgba(255,255,255,0.1)')
        .atmosphereAltitude(0.15);

      globe(globeContainer.value);
      globe.controls().autoRotate = true;
      globe.controls().autoRotateSpeed = 0.5;
      globe.pointOfView({ altitude: 2.5 });
    };

    const handleResize = () => {
      if (globe) {
        const isMobile = window.innerWidth <= 768;
        if (isMobile) {
          const globeSize = Math.min(window.innerWidth, window.innerHeight * 0.6);
          globe.width(globeSize);
          globe.height(globeSize);
          globe.pointOfView({ altitude: 2.0 });
        } else {
          globe.width(globeContainer.value.clientWidth);
          globe.height(globeContainer.value.clientHeight);
          globe.pointOfView({ altitude: 2.5 });
        }
      }
    };

    watch(() => props.coins, (newCoins) => {
      if (newCoins.length > 0) {
        if (!initialSelectionDone) {
          selectRandomCoins();
        } else {
          // Actualizar solo los precios de las monedas seleccionadas
          selectedCoins.value = selectedCoins.value.map(selectedCoin => {
            const updatedCoin = newCoins.find(c => c.id === selectedCoin.id);
            return updatedCoin || selectedCoin;
          });
        }
      }
    }, { immediate: true, deep: true });

    onMounted(() => {
      initGlobe();
      handleResize();
      window.addEventListener('resize', handleResize);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', handleResize);
    });

    return {
      globeContainer,
      selectedCoins,
      formatNumber,
      getPositionClass
    };
  }
});
</script>

<style scoped>
.globe-container {
  width: 100%;
  height: 120rem;
  position: relative;
  background-color: black;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.global-title {
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  text-align: center;
  width: 100%;
}

.global-title h2 {
  color: rgb(252, 252, 252);
  font-size: 4rem;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  background: #ffffff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 2px;
}

.globe-wrapper {
  width: 100%;
  flex: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.globe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.crypto-cards {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 180px);
  pointer-events: none;
  z-index: 2;
}

.crypto-card {
  position: absolute;
  background: linear-gradient(135deg, #030022 0%, #4B2572 100%);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  padding: 16px;
  color: white;
  width: 320px;
  backdrop-filter: blur(8px);
  display: flex;
  gap: 16px;
  pointer-events: auto;
  transition: all 0.3s ease;
  box-shadow: 0 4px 20px rgba(75, 37, 114, 0.2);
}

.crypto-card:hover {
  transform: translateY(-2px);
  border-color: rgba(255, 255, 255, 0.4);
  box-shadow: 0 8px 32px rgba(75, 37, 114, 0.4);
  background: linear-gradient(135deg, #030022 20%, #4B2572 120%);
}

.top-left { top: 20%; left: 25%; }
.top-right { top: 20%; right: 20%; }
.left-center { top: 45%; left: 15%; transform: translateY(-50%); }
.bottom-left { bottom: 20%; left: 22%; }
.bottom-right { bottom: 25%; right: 22%; }

.crypto-icon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.crypto-info { flex: 1; }

.crypto-name {
  font-size: 2rem;
  margin-bottom: 8px;
  color: white;
}

.crypto-price, .crypto-cap {
  font-size: 1.5rem;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 4px;
}

.stats-wrapper {
  position: absolute;
  bottom: -200px;
  left: 0;
  width: 100%;
  background: linear-gradient(to top, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 100%);
  z-index: 10;
  transform: translateY(-200px);
}

.community-stats-card {
  background: rgba(13, 12, 34, 0.8);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  padding: 15px 80px;
  color: white;
  width: 90%;
  max-width: 1000px;
  margin: 0 auto;
}

.stats-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
}

/* Estilos para desktop/mobile */
.desktop-stats {
  display: block;
}

.mobile-stats {
  display: none;
}

.mobile-stats-container {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.mobile-stats-header {
  text-align: center;
  margin-bottom: 1rem;
}

.mobile-stats-title {
  font-size: 2.4rem;
  color: white;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  line-height: 1.2;
  background: linear-gradient(to bottom, #ffffff, #a8a8a8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

.mobile-stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 0.5rem;
}

.mobile-stat-label {
  font-size: 1.8rem;
  color: rgba(255, 255, 255, 0.9);
  margin: 0;
  text-transform: uppercase;
}

.mobile-stat-value {
  font-size: 4rem;
  color: white;
  margin: 0;
  font-weight: bold;
}

@media (max-width: 1400px) {
  .crypto-card { width: 300px; }
}

@media (max-width: 768px) {
  .globe-container {
    height: 120vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 2rem 0;
    overflow-y: auto;
  }

  .global-title {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    margin-bottom: 2rem;
  }

  .globe-wrapper {
    position: relative;
    height: 70vh;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    top: auto;
  }

  .crypto-cards { display: none; }

  .stats-wrapper {
    position: relative;
    padding: 1rem;
    background: none;
    bottom: 0;
    transform: none;
    margin-top: 2rem;
    width: 100%;
  }

  .community-stats-card {
    padding: 2rem;
    width: 95%;
    margin: 0 auto;
    background: rgba(13, 12, 34, 0.95);
  }

  .desktop-stats {
    display: none;
  }

  .mobile-stats {
    display: block;
  }

  .mobile-stats-title {
    font-size: 2.2rem;
  }

  .mobile-stat-label {
    font-size: 1.6rem;
  }

  .mobile-stat-value {
    font-size: 3.5rem;
  }
}

@media (max-width: 380px) {
  .globe-container { 
    height: 130vh; 
  }

  .globe-wrapper { 
    height: 60vh; 
  }

  .stats-wrapper { 
    margin-top: 0; 
  }

  .community-stats-card {
    padding: 1.5rem;
  }

  .mobile-stats-container {
    padding: 1.5rem;
    gap: 1.5rem;
  }

  .mobile-stats-title {
    font-size: 2rem;
  }

  .mobile-stat-label {
    font-size: 1.4rem;
  }

  .mobile-stat-value {
    font-size: 3rem;
  }
}
</style>