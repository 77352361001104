<template>
    <div class="rbt-splash-coding-quality-area rbt-three-slider rbt-section-gapBottom">
      <div class="wrapper">
        <div class="container">
          <div class="row g-4">
            <!-- Start Top Feature  -->
            <div class="col-lg-4 col-md-6 col-12" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
              <div class="top-features-box h-100 text-center bg-gradient-15" style="box-shadow: none;">
                <div class="inner">
                  <div class="d-flex justify-content-center">
                    <img src="assets/images/splash/topfeature/01.png" alt="Image" class="feature-icon">
                  </div>
                  <div class="content mb-5">
                    <h4 class="title font-coolvetica letter-spacing-07">La clave en nanomercados es la información de calidad y el análisis profundo</h4>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Top Feature  -->
  
            <!-- Start Top Feature  -->
            <div class="col-lg-4 col-md-6 col-12" data-sal-delay="200" data-sal="slide-up" data-sal-duration="800">
              <div class="top-features-box h-100 text-center bg-gradient-15" style="box-shadow: none;">
                <div class="inner">
                  <div class="d-flex justify-content-center">
                    <img src="assets/images/splash/topfeature/02.png" alt="Image" class="feature-icon">
                  </div>
                  <div class="content mb-5">
                    <h4 class="title font-coolvetica letter-spacing-07">Diversificar en nano caps es esencial para mitigar riesgos en estos mercados volátiles</h4>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Top Feature  -->
  
            <!-- Start Top Feature  -->
            <div class="col-lg-4 col-md-6 col-12" data-sal-delay="250" data-sal="slide-up" data-sal-duration="800">
              <div class="top-features-box h-100 text-center bg-gradient-15" style="box-shadow: none;">
                <div class="inner">
                  <div class="d-flex justify-content-center">
                    <img src="assets/images/splash/topfeature/03.png" alt="Image" class="feature-icon">
                  </div>
                  <div class="content mb-5">
                    <h4 class="title font-coolvetica letter-spacing-07">Poseer conocimientos privilegiados o realizar investigaciones exhaustivas puede marcar la diferencia entre una inversión exitosa.</h4>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Top Feature  -->
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import sal from 'sal.js'
  import 'sal.js/dist/sal.css'
  
  export default {
    name: 'AreaOptions',
    mounted() {
      sal();
    }
  }
  </script>
  
  <style scoped>
  .top-features-box .inner img {
    mix-blend-mode: luminosity;
    opacity: 0.8;
    filter: grayscale(70%) brightness(1.5) contrast(1);
    transition: all 0.3s ease;
  }
  
  .top-features-box {
    background: linear-gradient(5deg, #f3f3f3, #ffffff);
  }
  
  .top-features-box:hover .inner img {
    opacity: 1;
    filter: grayscale(30%) brightness(1) contrast(1);
  }
  
  .feature-icon {
    width: 100%;
    max-width: 20rem;
    height: auto;
    object-fit: contain;
  }
  
  /* Responsive styles */
  @media (max-width: 1200px) {
    .feature-icon {
      max-width: 15rem;
    }
  }
  
  @media (max-width: 992px) {
    .col-lg-4 {
      width: 100%;
    }
  }
  
  @media (max-width: 768px) {
    .feature-icon {
      max-width: 12rem;
    }
  }
  
  @media (max-width: 576px) {
    .feature-icon {
      max-width: 10rem;
    }
    .top-features-box .inner .content h4.title {
      font-size: 1.2rem;
    }
  }
  </style>