<template>
  <div class="container mx-auto px-4 sm:px-8">
    <div class="py-8">
      <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
        <div class="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
          <table class="min-w-full leading-normal">
            <thead>
              <tr>
                <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-base font-semibold text-gray-700 uppercase tracking-wider">
                  Logo
                </th>
                <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-base font-semibold text-gray-700 uppercase tracking-wider">
                  Nombre
                </th>
                <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-base font-semibold text-gray-700 uppercase tracking-wider">
                  Precio
                </th>
                <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-base font-semibold text-gray-700 uppercase tracking-wider">
                  Market Cap
                </th>
                <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-base font-semibold text-gray-700 uppercase tracking-wider">
                  VWAP (24Hr)
                </th>
                <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-base font-semibold text-gray-700 uppercase tracking-wider">
                  Supply
                </th>
                <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-base font-semibold text-gray-700 uppercase tracking-wider">
                  Volumen (24h)
                </th>
                <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-base font-semibold text-gray-700 uppercase tracking-wider">
                  Cambio (24h)
                </th>
                <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-base font-semibold text-gray-700 uppercase tracking-wider">
                  Inversión
                </th>
                <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-base font-semibold text-gray-700 uppercase tracking-wider">
                  Ganancia NANO
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="coin in coins" :key="coin.id" :class="{ 'bg-green-200': coin.priceIncreased, 'bg-red-200': coin.priceDecreased }">
                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <div class="flex">
                    <div class="flex-shrink-0 w-10 h-10">
                      <img class="w-full h-full rounded-full" :src="coin.icon" :alt="coin.name" />
                    </div>
                  </div>
                </td>
                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <p class="text-gray-900 whitespace-no-wrap">{{ coin.name }} ({{ coin.symbol }})</p>
                </td>
                <td class="px-5 py-5 border-b border-gray-200">
                  <div class="flex items-center">
                    <i v-if="coin.priceIncreased" class="fas fa-caret-up text-green-500 mr-2" style="font-size: 1.5em;"></i>
                    <i v-if="coin.priceDecreased" class="fas fa-caret-down text-red-500 mr-2" style="font-size: 1.5em;"></i>
                    <span class="text-gray-900 whitespace-no-wrap flex items-center">
                      <span class="mr-1">$</span>
                      {{ formatNumber(coin.price) }}
                    </span>
                  </div>
                </td>
                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <p class="text-gray-900 whitespace-no-wrap">$ {{ formatNumber(coin.marketCap) }}</p>
                </td>
                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <p class="text-gray-900 whitespace-no-wrap">$ {{ formatNumber(coin.vwap24Hr) }}</p>
                </td>
                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <p class="text-gray-900 whitespace-no-wrap">{{ formatNumber(coin.supply) }}</p>
                </td>
                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <p class="text-gray-900 whitespace-no-wrap">$ {{ formatNumber(coin.volume) }}</p>
                </td>
                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <p :class="{'text-green-600': coin.changePercent24Hr > 0, 'text-red-600': coin.changePercent24Hr < 0}">
                    {{ formatNumber(coin.changePercent24Hr, 2) }}%
                  </p>
                </td>
                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <p class="text-gray-900 whitespace-no-wrap">$ {{ calculateInvestment(coin) }}</p>
                </td>
                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <p class="text-gray-900 whitespace-no-wrap">$ {{ calculateNano(coin) }}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue'

defineProps({
  coins: {
    type: Array,
    required: true,
    default: () => []
  }
})

const formatNumber = (value, decimals = 2) => {
  if (value === undefined || value === null) {
    return '0';
  }

  const num = Number(parseFloat(value));
  if (isNaN(num)) {
    return '0';
  }

  return num.toLocaleString(undefined, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  });
}

const calculateInvestment = (coin) => {
  const result = ((coin.volume / coin.supply) * coin.price) * 350;
  return Math.ceil(result);
}

const calculateNano = (coin) => {
  const result = ((coin.supply / coin.marketCap) * coin.price) * 350;
  return Math.ceil(result);
}
</script>

<style scoped>
.bg-green-200 {
  transition: background-color 1s;
}

.bg-red-200 {
  transition: background-color 1s;
}
</style>