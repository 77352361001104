<template>
  <div
    class="rbt-accordion-area accordion-style-1 rbt-accordion-color-white bg-color-darker rbt-section-gapBottom pt--60 pt_sm--0 overflow-hidden position-relative top-circle-shape-top overlpping-call-to-action"
  >
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-8 offset-lg-2 col-sm-12">
          <div class="section-title text-center pb--60">
            <h2 class="margin-mobile title mb_sm--0 text-center font-coolvetica letter-spacing-1 text-white">
              Tienes alguna pregunta?
            </h2>
          </div>
          <div class="rbt-accordion-style rbt-accordion-02 accordion">
            <div class="accordion" id="accordionExamplea1">
                <div class="accordion-item card" v-for="(faq, index) in faqs" :key="faq.id">
                <h2 class="accordion-header card-header" :id="'heading' + index">
                <button
                    class="accordion-button font-coolvetica letter-spacing-06 text-white"
                    :class="{ collapsed: !faq.isOpen }"
                    type="button"
                    @click="toggleAccordion(index)"
                    :aria-expanded="faq.isOpen"
                    :aria-controls="'collapse' + index"
                >
                    {{ faq.question }}
                </button>
                </h2>
                <div
                    :id="'collapse' + index"
                    class="accordion-collapse collapse"
                    :class="{ show: faq.isOpen }"
                    :aria-labelledby="'heading' + index"
                    data-bs-parent="#accordionExamplea1"
                >
                    <div class="accordion-body card-body">
                        {{ faq.answer }}
                    </div>
                </div>
            </div>

              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const faqs = ref([
  {
    id: 1,
    question: "¿Qué son los nanomercados?",
    answer:
      "Los nanomercados son segmentos de mercado compuestos por activos, como criptomonedas o empresas, con capitalizaciones extremadamente bajas. Estos mercados suelen ser volátiles y menos eficientes, ofreciendo oportunidades de inversión para aquellos dispuestos a asumir mayores riesgos.",
  },
  {
    id: 2,
    question: "¿Qué son las nano caps?",
    answer:
      "Las nano caps son criptomonedas o empresas con una capitalización de mercado muy pequeña. Aunque su tamaño es reducido, pueden ofrecer un alto potencial de crecimiento, especialmente en sus etapas iniciales, aunque conllevan un riesgo considerable debido a su alta volatilidad.",
  },
  {
    id: 3,
    question: "¿Por qué invertir en nano caps puede ser rentable?",
    answer:
      "Invertir en nano caps puede ser rentable debido a su potencial para crecer rápidamente, aprovechando su baja capitalización inicial. Además, su volatilidad puede generar rendimientos significativos en cortos periodos, aunque esto también implica un mayor riesgo.",
  },
  {
    id: 4,
    question: "¿Cuáles son los riesgos asociados con la inversión en nano caps?",
    answer:
      "El principal riesgo es la alta volatilidad, que puede resultar en pérdidas rápidas y significativas. Además, las nano caps suelen tener menor liquidez y menos cobertura de analistas, lo que puede dificultar la toma de decisiones informadas y aumentar la exposición al riesgo.",
  },
  {
    id: 5,
    question: "¿Cómo se pueden aprovechar las ineficiencias del mercado en nanomercados?",
    answer:
      "Las ineficiencias del mercado en nanomercados pueden aprovecharse mediante un análisis exhaustivo y la búsqueda de información de alta calidad. Los inversores informados pueden identificar y capitalizar discrepancias de precios, logrando rendimientos superiores al promedio del mercado.",
  },
  {
    id: 6,
    question: "¿Qué estrategias de inversión son efectivas en nanomercados?",
    answer:
      "En nanomercados, las estrategias basadas en el análisis fundamental y técnico son especialmente efectivas. Utilizar enfoques como el momentum y la reversión a la media puede ser beneficioso. Además, la diversificación es crucial para mitigar el riesgo inherente a estas inversiones.",
  },
  {
    id: 7,
    question: "¿Qué papel juega la información en la inversión en nano caps?",
    answer:
      "La información es fundamental en la inversión en nano caps. Dado que estos mercados suelen estar menos cubiertos por analistas, disponer de información exclusiva y realizar un análisis detallado puede ofrecer una ventaja significativa, permitiendo explotar las ineficiencias del mercado.",
  },
  {
    id: 8,
    question: "¿Cómo impacta la volatilidad en las nano caps?",
    answer:
      "La volatilidad impacta en las nano caps al hacer que sus precios fluctúen de manera considerable en cortos periodos. Aunque esto puede llevar a grandes ganancias, también implica un alto riesgo de pérdidas. Los inversores deben estar preparados para la naturaleza impredecible de estos activos.",
  },
  {
    id: 9,
    question: "¿Qué es la diversificación y por qué es importante en nanomercados?",
    answer:
      "La diversificación es la práctica de distribuir inversiones entre diferentes activos para reducir el riesgo. En nanomercados, es crucial porque ayuda a mitigar el impacto de la volatilidad extrema y las posibles pérdidas de cualquier activo individual, equilibrando la cartera general.",
  },
  {
    id: 9,
    question: "¿Cuál es el potencial de crecimiento de las criptomonedas en nanomercados?",
    answer:
      "El potencial de crecimiento de las criptomonedas en nanomercados es significativo, especialmente si logran aumentar su adopción y reconocimiento. Al estar en fases tempranas de desarrollo, estas criptomonedas pueden experimentar un crecimiento exponencial, ofreciendo altos retornos a los primeros inversores.",
  },
  
]);

const toggleAccordion = (index) => {
  faqs.value = faqs.value.map((faq, i) => ({
    ...faq,
    isOpen: i === index ? !faq.isOpen : false
  }));
};
</script>

<style scoped>
.collapse {
  visibility: visible !important;
}

.rbt-accordion-area {
  padding-bottom: 5em;
}
@media (max-width: 768px) {
  .margin-mobile {
    margin-top: 20px;
  }
}
</style>
