<template>
  
  <div id="app">
    
    <BodyComponent class="rbt-header-sticky" />
    <!-- <hr> -->
    <!-- Resto de tu aplicación -->
  </div>
</template>

<script>
import BodyComponent from '@/components/BodyComponent.vue'

export default {
  name: 'App',
  components: {
    BodyComponent,
  }
}
</script>