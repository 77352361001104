<template>
    <div class="rbt-advance-tab-area splash-layout-presentation pb--40 bg-color-white" style="margin-top: 10rem;">
        <div class="wrapper">
            <div class="container">
                <div class="row mb--60">
                    <div class="col-lg-12">
                        <div class="d-flex justify-content-center align-items-center mb-5 ">
                            <h3 class="coming-now p-4 font-coolvetica letter-spacing-08" @click="goToRegister">Empezar ahora <i
                                    class="ms-3 fa-solid fa-arrow-up-right-from-square"></i></h3>
                        </div>
                        <div class="section-title text-center">
                            <h2 class="title font-coolvetica letter-spacing-01">Tiempo real de los NANOCAPS donde invertimos</h2>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="wrapper">
                        <div class="container">
                            <div class="row g-5 d-flex justify-content-center">
                                <div class="col-lg-4 col-md-6 col-12 p-0" data-sal-delay="150" data-sal="slide-up"
                                    data-sal-duration="800">
                                    <div class="h-100 text-center">
                                        <div class="inner">
                                            <img src="assets/images/area/150+.png" alt="Image">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-12 p-0" data-sal-delay="150" data-sal="slide-up"
                                    data-sal-duration="800">
                                    <div class="h-100 text-center">
                                        <div class="inner">
                                            <img src="assets/images/area/25+.png" alt="Image">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="splash-layout-presentation-box">
                            <div class="row g-5">
                                <div class="col-12 order-1 order-lg-2">
                                    <div class="tab-content">
                                        <CryptoTable2 :coins="coins" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-10 mx-auto">
                        <ul class="plan-offer-list d-block d-sm-flex justify-content-center text-center">
                            <li class="d-flex align-items-center border border-gray rounded-lg shadow px-3" style="border-radius: 20px;">
                                <span class="text-black me-4 font-coolvetica" style="font-size: 8rem;">1.</span>
                                <span class="text-start text-black font-coolvetica letter-spacing-04">Los nanomercados permiten acceder a criptomonedas emergentes
                                    con alto potencial de crecimiento</span>
                            </li>
                            <li class="d-flex align-items-center border border-gray rounded-lg shadow px-3" style="border-radius: 20px;">
                                <span class="text-black me-4 font-coolvetica" style="font-size: 8rem;">2.</span>
                                <span class="text-start text-black font-coolvetica letter-spacing-04">Nano caps ofrecen oportunidades de inversión únicas en
                                    mercados menos cubiertos</span></li>
                            <li class="d-flex align-items-center border border-gray rounded-lg shadow px-3" style="border-radius: 20px;">
                                <span class="text-black me-4 font-coolvetica" style="font-size: 8rem;">3.</span>
                                <span class="text-start text-black font-coolvetica letter-spacing-04">La alta volatilidad de nano caps puede traducirse en
                                    ganancias rápidas para inversores atentos</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CryptoTable2 from '@/components/CryptoTable2.vue'

export default {
    name: 'CustomizerOptions',
    components: {
        CryptoTable2
    },
    props: {
        coins: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    methods: {
        goToRegister() {
            window.location.href = "https://my.blockblastworld.com/register/xMRC0uGKaJI";
        }
    }
}
</script>

<style scoped>
.coming-now {
    border: 2px solid #000;
    padding: 10px 20px;
    transition: all 0.3s ease;
    cursor: pointer;
    border-radius: 50px;
}

.coming-now:hover {
    background-color: #000;
    color: #fff;
}
</style>