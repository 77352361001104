<template>
    <div class="rbt-elements-presentation-area overflow-hidden bg-color-white rbt-section-gapBottom pt--80 pb-2">
        <div class="wrapper">
            <div class="row">
                <div class="col-lg-12">
                    <div class="section-title text-center">
                        <!-- <span class="subtitle bg-primary-opacity">You don't need any external plugins.</span> -->
                        <h2 class="title font-coolvetica letter-spacing-1 m-mobile">Los Nanomercados suelen estar subvalorados <br> Ofreciendo un potencial de revalorización significativo</h2>
                    </div>
                </div>
            </div>

            <div class="scroll-animation-all-wrapper">
                <div class="scroll-animation-wrapper mt--30">
                    <div class="scroll-animation scroll-right-left">
                        <!-- Start Single Testimonial  -->
                        <div class="single-column-100">
                            <div class="rbt-categori-list font-coolvetica">
                                <a href="#">Metis (METIS)</a>
                                <a href="#">Storm (STORM)</a>
                                <a href="#">Blox (CDT)</a>
                                <a href="#">Kadena (KDA)</a>
                                <a href="#">Convex Finance (CVX)</a>
                                <a href="#">STASIS EURO (EURS)</a>
                                <a href="#">Osmosis (OSMO)</a>
                                <a href="#">Pax Dollar (USDP)</a>
                                <a href="#">Tokenlon Network Token (LON)</a>
                                <a href="#">Prom (PROM)</a>
                                <!-- <a href="#"><i class="feather-users"></i> Team</a>
                                <a href="#"><i class="feather-twitter"></i> Social</a>
                                <a href="#"><i class="feather-image"></i> Gallery</a>
                                <a href="#"><i class="feather-dollar-sign"></i> Pricing</a>
                                <a href="#"><i class="feather-percent"></i> Progressbar</a>
                                <a href="#"><i class="feather-message-square"></i> Testimonial</a>
                                <a href="#"><i class="feather-layout"></i> Service</a>
                                <a href="#"><i class="feather-map"></i> Split Area</a>
                                <a href="#"><i class="feather-search"></i> Search Style</a>
                                <a href="#"><i class="feather-instagram"></i> Instagram Style</a>
                                <a href="#"><i class="feather-list"></i> List Style</a>
                                <a href="#"><i class="feather-airplay"></i> Creative Tab</a> -->
                            </div>
                        </div>
                        <!-- End Single Testimonial  -->
                    </div>
                </div>

                <div class="scroll-animation-wrapper mt--30">
                    <div class="scroll-animation scroll-left-right">
                        <!-- Start Single Testimonial  -->
                        <div class="single-column-100">
                            <div class="rbt-categori-list">
                                <span>Kusama (KSM)</span>
                                <span>Ravencoin (RVN)</span>
                                <span>Dash (DASH)</span>
                                <span>Enjin Coin (ENJ)</span>
                                <span>Siacoin (SC)</span>
                                <span>Ankr (ANKR)</span>
                                <span>aelf (ELF)</span>
                                <span>Basic Attention Token (BAT)</span>
                                <span>Qtum (QTUM)</span>
                                <span>UMA (UMA)</span>
                                <span>Storj (STORJ)</span>
                                <span>Illuvium (ILV)</span>
                                <span>Sun [New] (SUN)</span>
                                <span>Band Protocol (BAND)</span>
                                <span>Loopring (LRC)</span>
                                <!-- <span><i class="feather-user-check"></i> Team Member</span>
                                <span><i class="feather-star"></i> Review</span>
                                <span><i class="feather-voicemail"></i> Subscribe</span>
                                <span><i class="feather-jash"></i> Counter</span>
                                <span><i class="feather-skip-back"></i> Go to Top</span>
                                <span><i class="feather-filter"></i> Isotope Filter</span>
                                <span><i class="feather-bookmark"></i> Bookmark</span> -->
                            </div>
                        </div>
                        <!-- End Single Testimonial  -->
                    </div>
                </div>

                <div class="scroll-animation-wrapper mt--30">
                    <div class="scroll-animation scroll-right-left">
                        <!-- Start Single Testimonial  -->
                        <div class="single-column-100">
                            <div class="rbt-categori-list">
                                <a href="#">Mask Network (MASK)</a>
                                <a href="#">Rocket Pool (RPL)</a>
                                <a href="#">Tokamak Network (TON)</a>
                                <a href="#">SKALE (SKL)</a>
                                <a href="#">OriginTrail (TRAC)</a>
                                <a href="#">Decred (DCR)</a>
                                <a href="#">dYdX (DYDX)</a>
                                <a href="#">Yield Guild Games (YGG)</a>
                                <a href="#">SushiSwap (SUSHI)</a>
                                <a href="#">Harmony (ONE)</a>
                                <!-- <a href="#"><i class="feather-watch"></i>Countdown Timer</a>
                                <a href="#"><i class="feather-pie-chart"></i> Radial Progress</a>
                                <a href="#"><i class="feather-command"></i> Parallax</a>
                                <a href="#"><i class="feather-search"></i> Advance Search</a>
                                <a href="#"><i class="feather-tv"></i> Content Box</a>
                                <a href="#"><i class="feather-shopping-cart"></i> Minicart</a>
                                <a href="#"><i class="feather-sidebar"></i> Offcanvas</a>
                                <a href="#"><i class="feather-chevrons-right"></i> Image Carousel</a>
                                <a href="#"><i class="feather-italic"></i> Sliding Heading</a>
                                <a href="#"><i class="feather-paperclip"></i> Tag cloud</a>
                                <a href="#"><i class="feather-menu"></i> Hamburger</a>
                                <a href="#"><i class="feather-zap"></i> Advance Dropdown</a>
                                <a href="#"><i class="feather-skip-forward"></i> Pagination</a> -->
                            </div>
                        </div>
                        <!-- End Single Testimonial  -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'ElementIncluded',
}
</script>

<style scoped>
</style>