<template>

    <div class="pre-footer-area bg-color-darker" style="padding-bottom: 10em;">
        <div class="container" style="padding-left: 2em;">
            <div class="row">
                <div class="col-12">
                    <div class="d-flex flex-column flex-md-row align-items-center justify-content-between">
                        <div class="col-12 col-md-1 mb-3 mb-md-0 d-flex justify-content-center">
                            <img :src="LogoCrypto" alt="Logo Crypto" class="img-fluid">
                        </div>
                        <div class="col-12 col-md-8 mb-3 mb-md-0">
                            <hr class="d-none d-md-block m-0 ms-5">
                        </div>
                        <div class="col-12 col-md-3 text-center text-md-end d-flex justify-content-evenly">
                            <img 
                                @click="goTo('https://www.instagram.com/blockblast_world/')" 
                                :src="InstagramIco" 
                                width="40" 
                                alt="instagram" 
                                role="button"
                            >
                            <img 
                                @click="goTo('https://www.youtube.com/@BlockBlastworld')"
                                :src="YoutubeIco" 
                                width="40" 
                                alt="Youtube" 
                                role="button"
                            >
                            <img 
                                @click="goTo('https://t.me/blockblastworld')"
                                :src="TelegramIco" 
                                width="40" 
                                alt="telegram" 
                                role="button"
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column flex-md-row align-items-center justify-content-between mt-5 pb-5 me-5">
                <p class="text-white m-0 mb-3 mb-md-0 text-center text-md-start font-coolvetica letter-spacing-04">Last chance to become a crypto millionaire</p>
                <p class="text-white m-0 me-5 font-coolvetica letter-spacing-04">
                    <i class="fa-regular fa-envelope"></i>
                    support@blockblastcompany.com
                </p>
            </div>
        </div>
    </div>


    <div class="copyright-area copyright-style-1 ptb--20 bg-color-darker">
        <div class="container">
            <hr>
            <div class="row justify-content-end">
                <div class="col-12">
                    <div class="d-flex justify-content-end align-items-center flex-wrap footer-margin-desktop font-coolvetica letter-spacing-03">
                        <p class="rbt-link-hover text-white text-end mb-0 me-3">block blast - Todos los derechos reservados 2024 ©</p>
                        <p class="rbt-link-hover text-white text-end mb-0 me-3">Políticas de Privacidad</p>
                        <p class="rbt-link-hover text-white text-end mb-0">Condiciones</p>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="rbt-progress-parent">
        <svg class="rbt-back-circle svg-inner" width="100%" height="100%" viewBox="-1 -1 102 102">
            <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
    </div>
</template>

<script setup>
import LogoCrypto from "../../public/assets/images/logo/logo-crypto.png"; 
import YoutubeIco from "../../public/assets/images/logo/youtube.png"; 
import InstagramIco from "../../public/assets/images/logo/instagram.png"; 
import TelegramIco from "../../public/assets/images/logo/telegram.png"; 

const goTo = (url) => {
  window.location.href = url;
}
</script>

<style scoped>

@media (min-width: 520px) {
    .footer-margin-desktop {
        margin-right: 5em;
    }
}
</style>