<template>
  <div class="course-showcase">
    <div class="background-container-cohete d-flex justify-content-center align-items-center">
      <div class="content-wrapper">
        <h2 class="title font-coolvetica letter-spacing-1">
          Acceder a información exclusiva en <br class="desktop-break" /> nano-caps es clave para maximizar rendimientos
        </h2>
        <button class="access-button mt-5 font-coolvetica letter-spacing-08" @click="accessCommunity">
          ACCEDER A NUESTRA COMUNIDAD
        </button>
      </div>
    </div>
    <div class="gradient-div font-coolvetica letter-spacing-2 hide-mobile">
      <Carousel
        :items-to-show="itemsToShow"
        :wrap-around="true"
        :autoplay="1"
        :pause-autoplay-on-hover="false"
        :transition="20000"
      >
        <Slide v-for="(item, index) in carouselItems" :key="index">
          <div class="carousel-item whitespace-nowrap">
            {{ item }}
            <img v-if="index < carouselItems.length" src="/assets/images/splash/topfeature/iconCohete.png" alt="Icon" class="carousel-icon">
          </div>
        </Slide>
      </Carousel>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

export default {
  name: 'CourseShowcase',
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      carouselItems: ['NANOMARKETS', 'BLOCK BLAST', 'NANOMARKETS', 'START NOW', 'BLOCK BLAST'],
      itemsToShow: 3.5,
    }
  },
  methods: {
    accessCommunity() {
      const urlTelegram = 'https://t.me/blockblastworld';
      window.open(urlTelegram, '_blank');
    },
    handleResize() {
      if (window.innerWidth < 768) {
        this.itemsToShow = 1;
      } else if (window.innerWidth < 1024) {
        this.itemsToShow = 2;
      } else {
        this.itemsToShow = 3.5;
      }
    }
  },
  mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
}
</script>

<style scoped>
.course-showcase {
  display: flex;
  flex-direction: column;
}


.content-wrapper {
  text-align: center;
  max-width: 80%;
  position: relative;
  z-index: 2;
}

.title {
  font-size: 4rem;
  color: white;
  margin-bottom: 20px;
  text-transform: uppercase;
  /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); */
}

.access-button {
  background-color: white;
  color: black;
  border: none;
  padding: 20px 40px;
  font-size: 3rem;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 40px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.access-button:hover {
  background-color: #f0f0f0;
  transform: scale(1.05);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.gradient-div {
  width: 100%;
  height: 80px;
  background: linear-gradient(to right, #05afca, #531fbc);
  overflow: hidden;
}

.carousel-item {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  padding: 0 20px;
}

.carousel-icon {
  width: 31rem;
  height: 5rem;
  margin-left: 40px;
  object-fit: contain;
}

.carousel-item {
  white-space: nowrap;
  /* O también puedes usar */
  /* text-wrap: nowrap; */
}

:deep(.carousel__track) {
  transition: all 20s linear !important;
}

:deep(.carousel__slide) {
  margin: 0 10px;
}

:deep(.carousel__viewport) {
  perspective: none;
}

/* Responsive styles */
@media (max-width: 1200px) {
  .title {
    font-size: 3rem;
  }
  .access-button {
    font-size: 2.5rem;
    padding: 15px 30px;
  }
}

@media (max-width: 992px) {
  .title {
    font-size: 2.5rem;
  }
  .access-button {
    font-size: 2rem;
    padding: 15px 25px;
  }
  .carousel-icon {
    width: 25rem;
    height: 4rem;
  }
}

@media (max-width: 768px) {
  .title {
    font-size: 2rem;
  }
  .access-button {
    font-size: 1.5rem;
    padding: 10px 20px;
  }
  .carousel-item {
    font-size: 1.5rem;
  }
  .carousel-icon {
    width: 20rem;
    height: 3rem;
    margin-left: 20px;
  }
  .desktop-break {
    display: none;
  }
}

@media (max-width: 576px) {
  .title {
    font-size: 1.5rem;
  }
  .access-button {
    font-size: 1.2rem;
    padding: 10px 15px;
  }
  .carousel-item {
    font-size: 1.2rem;
  }
  .carousel-icon {
    width: 15rem;
    height: 2.5rem;
    margin-left: 10px;
  }
}
</style>