<template>
    <div class="home-demo-area rbt-section-gap pb-0 bg-gradient-6 splash-masonary-wrapper-activation pt-0">
        <div class="background-overlay"></div> <!-- Añade este div -->
        <div class="grayscale-background"
            :style="{ backgroundImage: `url(${BgBTC})`}">
        </div>
        <div class="col-lg-10 offset-lg-1 col-xl-6 offset-xl-3">
            <div class="section-title text-center has-section-before-title mb--150 mt--50 mb_lg--100 mb_md--100 mb_sm--100">
                <h2 class="rbt-display-1 text-white font-coolvetica letter-spacing-1" style="font-size: 3em !important;">Impacto de la Información</h2>
                <h3 class="title text-white font-coolvetica letter-spacing-08">La Llave para Desbloquear Rendimientos Excepcionales</h3>
                <div class="indicator-icon" :style="{
                    display: 'flex',
                    justifyContent: 'center',
                    cursor: 'pointer'
                }" @click="test">
                    <img class="edu_bounce_loop" @click="goToRegister" :src="BtnColor" style="min-width: 225px;">
                </div>
                <p class="description has-medium-font-size mt--20 text-white font-coolvetica letter-spacing-06">
                    La calidad y cantidad de información disponible sobre una criptomoneda nano cap pueden
                    influir drásticamente en su rendimiento en el mercado. El estudio de Bushee y Noe (2000)
                    demuestra que los inversores que tienen acceso a información de alta calidad y que pueden
                    realizar análisis detallados están en una posición privilegiada para aprovechar las
                    ineficiencias del mercado. En el entorno de las criptomonedas, donde la transparencia y la
                    difusión de información varían ampliamente, poseer conocimientos privilegiados o realizar
                    investigaciones exhaustivas puede marcar la diferencia entre una inversión exitosa y una
                    oportunidad perdida.
                </p>
            </div>
        </div>
        <div class="pt-0 tab-section" style="background-color: #000;">
            <VideoSection v-if="false" class="pb-5"/>
            <iframe class="mb-5" width="100%" height="600" src="https://www.youtube.com/embed/M1w7Z6qBASs?si=EOYuIT2a2lVcN9lx" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            <div class="row">
                <div class="col-lg-12">
                    <div class="rbt-portfolio-filter filter-button-default messonry-button text-start justify-content-center mb--5 mb_md--30 mb_sm--30 font-coolvetica letter-spacing-05">
                        <button @click="setActiveTab('nano')" :class="{ 'is-checked': activeTab === 'nano' }">
                            <span class="filter-text">Un Mercado Emergente</span>
                        </button>
                        <button @click="setActiveTab('marketplace')" :class="{ 'is-checked': activeTab === 'marketplace' }">
                            <span class="filter-text">Volatilidad y Oportunidades de Ganancia</span>
                        </button>
                        <button @click="setActiveTab('university')" :class="{ 'is-checked': activeTab === 'university' }">
                            <span class="filter-text">Ineficiencias del Mercado</span>
                        </button>
                        <button @click="setActiveTab('instructor')" :class="{ 'is-checked': activeTab === 'instructor' }">
                            <span class="filter-text">Potencial de Crecimiento</span>
                        </button>
                        <button @click="setActiveTab('singlecourse')" :class="{ 'is-checked': activeTab === 'singlecourse' }">
                            <span class="filter-text">Rendimientos Ajustados por Riesgo</span>
                        </button>
                        <button @click="setActiveTab('career')" :class="{ 'is-checked': activeTab === 'career' }">
                            <span class="filter-text">Estrategias de Inversión en Nanomercados Cripto</span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div style="margin-top: 5rem;">
                        <div class="maso-item mb-5" v-show="activeTab === 'nano'">
                            <div class="single-demo">
                                <div class="single-demo-link text-start">
                                    <p class="mb-0 font-coolvetica letter-spacing-05"><strong>Mercado Emergente con Potencial Exponencial</strong></p>
                                    <p class="font-cairo">Los nanomercados y nano caps en criptomonedas ofrecen un nicho con gran potencial para inversores en busca de altos rendimientos. Aunque volátiles, estas pequeñas capitalizaciones pueden generar crecimiento exponencial, presentando oportunidades significativas frente a criptos más consolidadas.</p>
                                </div>
                            </div>
                        </div>

                        <div class="maso-item mb-5" v-show="activeTab === 'marketplace'">
                            <div class="single-demo">
                                <div class="single-demo-link text-start">
                                    <p class="mb-0 font-coolvetica letter-spacing-05"><strong>Volatilidad y Oportunidades de Ganancia: Un Doble Filo con Potencial Elevado</strong></p>
                                    <p class="font-cairo">La volatilidad de las nano caps en cripto, aunque intimidante, puede ser aprovechada para obtener rendimientos sobresalientes. Las criptos de pequeña capitalización han mostrado, históricamente, mejor desempeño que las de mayor tamaño, con aumentos rápidos de valor.</p>
                                </div>
                            </div>
                        </div>

                        <div class="maso-item mb-5" v-show="activeTab === 'university'">
                            <div class="single-demo">
                                <div class="single-demo-link text-start">
                                    <p class="mb-0 font-coolvetica letter-spacing-05"><strong>Ineficiencias del Mercado: Oportunidades para Inversores Informados</strong></p>
                                    <p class="font-cairo">Los nanomercados cripto suelen estar subvalorados debido a su baja visibilidad. Estas ineficiencias pueden ser explotadas por inversores informados, lo que permite obtener rendimientos anormales aprovechando discrepancias de precios y valoraciones mal interpretadas.</p>
                                </div>
                            </div>
                        </div>

                        <div class="maso-item mb-5" v-show="activeTab === 'instructor'">
                            <div class="single-demo">
                                <div class="single-demo-link text-start">
                                    <p class="mb-0 font-coolvetica letter-spacing-05"><strong>Potencial de Crecimiento: De Nano a Gigante en el Mundo Cripto</strong></p>
                                    <p class="font-cairo">Las nano caps cripto, en fases tempranas, tienen un alto potencial de crecimiento. Invertir temprano, antes de su adopción masiva, puede generar retornos exponenciales, dado que su evolución hacia capitalizaciones mayores ocurre con rapidez.</p>
                                </div>
                            </div>
                        </div>

                        <div class="maso-item mb-5" v-show="activeTab === 'singlecourse'">
                            <div class="single-demo">
                                <div class="single-demo-link text-start">
                                    <p class="mb-0 font-coolvetica letter-spacing-05"><strong>Rendimientos Ajustados por Riesgo: La Prima de la Volatilidad en Cripto</strong></p>
                                    <p class="font-cairo">Las inversiones en nano caps cripto tienden a ofrecer rendimientos superiores gracias a su alto riesgo y volatilidad. Los inversores con alta tolerancia al riesgo pueden aprovechar las fluctuaciones agudas para obtener una prima de rendimiento significativa.</p>
                                </div>
                            </div>
                        </div>

                        <div class="maso-item mb-5" v-show="activeTab === 'career'">
                            <div class="single-demo">
                                <div class="single-demo-link text-start">
                                    <p class="mb-0 font-coolvetica letter-spacing-05"><strong>Estrategias de Inversión en Nanomercados Cripto: Navegando la Volatilidad con Inteligencia</strong></p>
                                    <p class="font-cairo">Para aprovechar las oportunidades en los nanomercados cripto, es vital aplicar estrategias basadas en análisis profundo y diversificación. En estos mercados, enfoques como el momentum y la reversión a la media han demostrado ser eficaces en maximizar retornos y minimizar riesgos.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import BgBTC from "../../public/assets/images/bg/bg-5.png";
import VideoSection from '@/components/VideoSection.vue'
import BtnColor from "../../public/assets/images/others/btn-1.png";

const activeTab = ref('nano');

const setActiveTab = (tab) => {
    activeTab.value = tab;
}

const test = () => {
    console.log("test");
}

const goToRegister = () => {
  window.location.href = "https://my.blockblastworld.com/register/xMRC0uGKaJI";
}
</script>

<style scoped>
.home-demo-area {
    position: relative;
    overflow: hidden;
    background-color: #000 !important; /* Asegura un fondo negro consistente */
}
.background-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7); /* Overlay negro semi-transparente */
    z-index: 0;
}
.grayscale-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover; /* Añade esta línea */
    opacity: 0.5; /* Ajusta la opacidad según necesites */
    z-index: -1;
}

.col-lg-10 {
    position: relative;
    z-index: 1;
}

.tab-section {
    position: relative;
    z-index: 1;
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: #000 !important; /* Fuerza el fondo negro */
}

.demo-presentation-mesonry {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
}

.maso-item {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.single-demo-link {
    text-align: center;
}
.single-demo .single-demo-link .tabs-section::after {
    display: none;
}

.single-demo-link p {
    color: #000;
    font-size: 18px;
    line-height: 1.6;
    padding: 20px;
}

.rbt-portfolio-filter {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-bottom: 30px;
}


@media screen and (max-width: 768px) {
    .description.has-medium-font-size {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }
}

/* Si necesitas ajustar específicamente para móviles más pequeños */
@media screen and (max-width: 480px) {
    .description.has-medium-font-size {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }
}
</style>