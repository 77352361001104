<template>
  <div class="rbt-splash-slider d-flex align-items-center">
    <div class="wrapper">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-12 col-xl-6 order-2 order-xl-1">
            <div class="inner text-center text-xl-start">
              <h1 class="title text-white font-coolvetica letter-spacing-2">NANO <br> MERCADOS</h1>
              <h4 class="subtitle text-white font-coolvetica letter-spacing-08">UNA VENTAJA DEL NUEVO SIGLO</h4>
              <p class="description text-white font-cairo ">Las nano caps de ser desconocidas a ser gigantes en el espacio cripto
              </p>
            </div>
          </div>
          <div class="col-12 col-xl-6 order-1 order-xl-2 mb-4 mb-xl-0">
            <div class="video-popup-wrapper">
              <img class="w-100 rbt-radius" src="assets/images/splash/banner-group-image.png" alt="Video Images">
            </div>
          </div>
        </div>
        <div class="text-xl-start text-center">
          <button style="background: none; border: none" @click="goToRegister">
            <img :src="BtnComenzar" alt="ingresar" style="width: 225px;">
          </button>
        </div>
        <div class="row mt-4">
          <div class="col-12">
            <div class="splash-service-main position-relative">
              <div class="service-wrapper service-white">
                <div class="row g-0">
                  <div v-for="(service, index) in services" :key="index"
                    class="col-12 col-sm-6 col-xl-3 service__style--column mb-4 mb-xl-0">
                    <div class="service service__style--1 font-coolvetica">
                      <div class="icon">
                        <h2 class="text-white">{{ index + 1 }}.</h2>
                      </div>
                      <div class="content">
                        <h4 class="title mb-3 letter-spacing-06">{{ service.title }}</h4>
                        <p class="font-cairo letter-spacing-32">{{ service.description }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script setup>
import { ref } from 'vue';
import BtnComenzar from "../../public/assets/images/others/btn-1.png";

const services = ref([
  {
    title: 'Los nanomercados presentan oportunidades únicas debido a su alta volatilidad',
    description: 'Nanomercados permiten a los inversores acceder a activos con menor cobertura mediática, pero alto potencial.'
  },
  {
    title: 'Invertir en nano caps puede generar rendimientos elevados',
    description: 'La inversión temprana en nano caps puede resultar en grandes beneficios.'
  },
  {
    title: 'Las ineficiencias de los nanomercados permiten aprovechar discrepancias de precios',
    description: 'Nano caps son vehículos ideales para estrategias de inversión avanzadas.'
  },
  {
    title: 'Las criptomonedas emergentes en nanomercados tienen un potencial de crecimiento exponencial',
    description: 'Nanomercados ofrecen una combinación única de riesgo y recompensa que atrae a inversores audaces.'
  }
]);

const goToRegister = () => {
  window.location.href = "https://my.blockblastworld.com/register/xMRC0uGKaJI";
}
</script>

<style scoped>
@media (max-width: 1199px) {
  .inner {
    margin-bottom: 2rem;
  }
}

@media (max-width: 767px) {
  .title {
    font-size: 2.5rem;
  }

  .subtitle {
    font-size: 1.5rem;
  }
}

@media (max-width: 575px) {
  .service__style--1 {
    padding: 1rem;
  }

  /* .service__style--1 .title {
    font-size: 1.2rem;
  } */
}
</style>